// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ffa834;//#0bcea6;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: //#10af91;
  --ion-color-primary-tint:  #10af91;

  /** secondary **/
  --ion-color-secondary: #B1DA53;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #B7D17D;
  --ion-color-secondary-tint: #C4E086;

  /** tertiary **/
  --ion-color-tertiary: #F80D6A;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #AD0648;
  --ion-color-tertiary-tint: #F36BA2;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #FA8258;
  --ion-color-danger-tint: #FE642E;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #666;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #f4f5f8;
  --ion-color-light-tint: #f5f6f9;
}


:root {
  --ion-color-favorite: #d5f9dd;
  --ion-color-favorite-rgb: 29, 161, 244;
  --ion-color-favorite-contrast: #666666;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #d5f9dd;
  --ion-color-favorite-tint: #d5f9dd;

  --ion-color-twitter: #29b6b3;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #29b6b3;
  --ion-color-twitter-tint: #29b6b3;

  --ion-color-google: #dc4a38;
  --ion-color-google-rgb: 220, 74, 56;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #c24131;
  --ion-color-google-tint: #e05c4c;

  --ion-color-vimeo: #76D49B;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255, 255, 255;
  --ion-color-vimeo-shade: #76D49B;
  --ion-color-vimeo-tint: #76D49B;

  --ion-color-facebook: #547507;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #547507;
  --ion-color-facebook-tint: #547507;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: //#69bb7b;
  --ion-color-food:// #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;


  --blue: #58330a;
  --primary-color: var(--orange);
  --custom-color: var(--blue);
  --red: #DB3A34;
  --green: #73BFB8;
  --yellow: #EDAE49;
  --pink: #D1495B;
  --orange : #ffa834;
  --light-brown : #5d4122;


  //custom color
  --my-custom-color: #422708;//black;// #422708;//linear-gradient(to right, #06cfa3 0%, #04ab8f 100%);
  --my-custom-label: #f60;
  --my-custom-title: #666666;
  --my-custom-price: #ffa834; //#CE3B41;
  --my-custom-tab: //#2AD2C9;
  --my-custom-background: #e3e3e3;
  --my-custom-border: #F2EFEF;
  --ion-toolbar-background: var(--my-custom-color);
  --ion-toolbar-color: #fff;

  --custom-gradient-images-primary: #d5d2d200;
  --custom-gradient-images-secondary: var(--ion-color-primary);
  --custom-gradient-image: linear-gradient(to bottom, var(--custom-gradient-images-primary) 0%, var(--custom-gradient-images-secondary) 100%);

  ion-toast {
    --background: var(--my-custom-color);
    color: white;
  }

   ion-toolbar {
    background:var(--ion-toolbar-background) !important;
  }

  ion-alert .alert-wrapper .alert-button-group {
    background: var(--ion-color-primary) !important;

    button {
      color: white;
    }
  }


}


.ios {
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
  /* Use the primary color as the background for the toolbar */
  // --ion-toolbar-background: var(--ion-color-primary);

  /* Change the color of the toolbar components */
  --ion-toolbar-color: #fff;

  /* Change the color of the activated toolbar components */
  --ion-toolbar-color-activated: #fff;

  /* Change the color of the unchecked segment buttons */
  --ion-toolbar-color-unchecked: rgba(255, 255, 255, .6);

  /* Change the color of the checked segment button */
  --ion-toolbar-color-checked: #fff;
}


