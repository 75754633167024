/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "global-custom";
@import "global-icon";


:root {
  /** primary **/
  --ion-color-primary:#ffa834;  // #117cb1; // #ffa834;//#0bcea6;
}
.title {
  color: var(--ion-color-dark) !important;
  font-size: 1rem !important;
}

.price {
  color: var(--ion-color-dark) !important;
  font-size: 14px !important;
  margin-left: 8px;
}

.num {
  color: var(--ion-color-medium-tint) !important;
  font-size: 14px !important;
}

.my-custom-alert {
  --min-width: 300px;
  --max-width: 300px;
  --width: 300px;
    // --min-height: 00px;
  // --max-height: 400px;
  // --height: 400px;
  --backdrop-opacity: 0.2;
  --alert-button-font-size : 70px;
  // --background: linear-gradient(90deg, rgba(165, 255, 192, 1) 1%, rgba(0, 212, 255, 1) 100%);

}


